<template>
  <v-card>
    <v-row>
      <v-col cols="12">
        <v-card color="green lighten-3">
          <v-card-title primary-title>
            <div>
              <div class="headline">
                {{ currentTilte }}
              </div>
              <span></span>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn text v-if="isCheck">Listen now</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ['token', 'emailB64'],
  data() {
    return {
      title: '',
      isCheck: false,
    }
  },
  created() {},
  computed: {
    currentTilte() {
      return this.title
    },
  },
  methods: {
    checkEmail() {},
  },
}
</script>

<style scoped></style>
